import { searchRequest } from "./constants/query";

export const buildSearchPayload = (
  must: Record<string, any> = {},
  mustNot: Record<string, any> = {},
  should: Record<string, any> = {},
  filter: Record<string, any> = {},
  sortFields: string[] = [],
  sortOrders: string[] = [],
  searchAfter: string = ""
) => {
  const query = { ...searchRequest };
  // Use spread operator to merge objects
  query.must = { ...query.must, ...must };
  query.mustNot = { ...query.mustNot, ...mustNot };
  query.should = { ...query.should, ...should };
  query.filter = { ...query.filter, ...filter };
  query.sortFields = [...query.sortFields, ...sortFields];
  query.sortOrders = [...query.sortOrders, ...sortOrders];
  query.searchAfter[0] = searchAfter;

  return query;
};
