import axios, { AxiosError, AxiosResponse, CancelToken } from "axios";
import { debounce } from "lodash";
import constant from "../utils/constants/constant.json";
import { defaultHeaders, formServiceUrl } from "./apiUrlUtil";
import { flattenPricePush, prepareProposedPriceKeys } from "./utils";
import { buildSearchPayload } from "./payloadBuilder";

debounce(function (callback) {
  return callback();
}, 300);

const elasticIndexMap = new Map<String, string>([
  ["GEOLOCATION", constant.urlConstants.elasticsearch.geolocationSearch],
  [
    "GEOLOCATION_WITH_FILTER",
    constant.urlConstants.elasticsearch.geolocationSearch,
  ],
  [
    "STORE_ATTRIBUTES",
    constant.urlConstants.elasticsearch.storeattributesSearch,
  ],
]);

const elasticIndexMappings = new Map<String, string>([
  ["GEOLOCATION", constant.urlConstants.elasticsearch.geolocationIndexName],
  [
    "GEOLOCATION_WITH_FILTER",
    constant.urlConstants.elasticsearch.geolocationIndexName,
  ],
  [
    "STORE_ATTRIBUTES",
    constant.urlConstants.elasticsearch.storeattributesIndexName,
  ],
]);

interface ElasticSearchResponseObject {
  hits: {
    hits: {
      sort: [];
      _id: string;
      _index: String;
      _score: any;
      _source: any;
      _type: String;
    }[];
    total: {
      value: number;
      relation: String;
    };
  };
}

export interface ListViewApiResponseObject {
  elasticSourceData: any[];
  elasticApiErrorMessage: any[];
}

export async function fetchListViewDataFromElasticSearch(): Promise<ListViewApiResponseObject> {
  const geolocationResponse = await fetchMoreThanThresholdDataFromElasticSearch(
    "GEOLOCATION"
  );
  const storeattributesResponse =
    await fetchMoreThanThresholdDataFromElasticSearch("STORE_ATTRIBUTES");
  const listViewImportCodeMap = mergeGelocationAndStoreAttributes(
    geolocationResponse,
    storeattributesResponse
  );
  const finalListViewArray = Array.from(listViewImportCodeMap.values());

  let result: ListViewApiResponseObject = {
    elasticSourceData: [],
    elasticApiErrorMessage: [],
  };
  geolocationResponse.elasticApiErrorMessage.length === 0
    ? ""
    : result.elasticApiErrorMessage.push(
        geolocationResponse.elasticApiErrorMessage
      );
  storeattributesResponse.elasticApiErrorMessage.length === 0
    ? ""
    : result.elasticApiErrorMessage.push(
        storeattributesResponse.elasticApiErrorMessage
      );
  result.elasticSourceData = finalListViewArray;

  console.debug("visops-list-view: final listview data", finalListViewArray);
  console.log(
    "visops-list-view: final listview size",
    finalListViewArray.length
  );
  return result;
}

export async function fetchMoreThanThresholdDataFromElasticSearch(
  elasticIndexDocumentKey: string
): Promise<ListViewApiResponseObject> {
  let batchSize = 5000;
  let elasticDataResponse: ListViewApiResponseObject = {
    elasticSourceData: [],
    elasticApiErrorMessage: [],
  };
  let lastArrayIndex: number = 0;
  let searchAfterIndex: string = "";

  do {
    let axiosSource = axios.CancelToken.source();
    let response: AxiosResponse<ElasticSearchResponseObject, any>;
    try {
      response = await _fetchStoresFromElasticSearchAfter(
        searchAfterIndex,
        batchSize,
        elasticIndexDocumentKey,
        axiosSource.token
      );
    } catch (error) {
      console.error(
        "visops-list-view: Elastic Search Axios call failed ",
        error
      );
      let errorMessage = `${error.message}: Failed to fetch ${elasticIndexMap
        .get(elasticIndexDocumentKey)
        .replace("/", "")
        .replace("/", "")
        .replace("_search", "")} results into listview `;
      elasticDataResponse.elasticApiErrorMessage.push(errorMessage);
    }
    if (response) {
      console.debug(
        "visops-list-view: fetch from",
        elasticIndexMap
          .get(elasticIndexDocumentKey)
          .replace("/", "")
          .replace("/", "")
          .replace("_", " "),
        "elastic search response",
        response
      );
      lastArrayIndex = response.data.hits.hits.length - 1;
      searchAfterIndex = response.data.hits.hits[lastArrayIndex]._id;
      console.debug(
        "visops-list-view: ",
        elasticIndexDocumentKey,
        " batch size ",
        lastArrayIndex + 1,
        "searchAfterIndex",
        searchAfterIndex
      );
      fillDataIntoSourceArray(
        response.data,
        elasticDataResponse.elasticSourceData
      );
    } else {
      return elasticDataResponse;
    }
  } while (lastArrayIndex + 1 >= batchSize);
  console.log(
    "visops-list-view:",
    elasticIndexMap
      .get(elasticIndexDocumentKey)
      .replace("/", "")
      .replace("/", "")
      .replace("_", " "),
    "totalSize",
    elasticDataResponse.elasticSourceData.length
  );
  return elasticDataResponse;
}

export async function fetchUpdatedColoumns(): Promise<ListViewApiResponseObject> {
  const geolocationResponse: ListViewApiResponseObject =
    await fetchMoreThanThresholdDataFromElasticSearch(
      "GEOLOCATION_WITH_FILTER"
    );
  const geolocationData: any[] = geolocationResponse.elasticSourceData
    .map(flattenPricePush)
    .map(prepareProposedPriceKeys);
  console.debug("visops-list-view: geolocationdata called", geolocationData);
  let result: ListViewApiResponseObject = {
    elasticSourceData: [],
    elasticApiErrorMessage: [],
  };
  geolocationResponse.elasticApiErrorMessage.length === 0
    ? ""
    : result.elasticApiErrorMessage.push(
        geolocationResponse.elasticApiErrorMessage
      );
  result.elasticSourceData = geolocationData;

  return result;
}

async function _fetchStoresFromElasticSearchAfter(
  searchAfterIndex: string,
  batchSize: number,
  documentName: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ElasticSearchResponseObject, any>> {
  const elasticSearchQuery = getQuery(documentName, searchAfterIndex);
  const { name, searchPath } = constant.urlConstants.listViewBFF;
  return await axios.post(
    await formServiceUrl(
      // constant.urlConstants.elasticsearch.name,
      name,
      searchPath,
      new Map<string, any>([
        ["index", elasticIndexMappings.get(documentName)],
        ["size", batchSize],
      ])
    ),
    elasticSearchQuery,
    {
      headers: defaultHeaders(),
    }
  );
}

function getQuery(documentName: string, searchAfterIndex: string) {
  switch (documentName) {
    case "GEOLOCATION":
      return buildSearchPayload(
        { isCompetitor: false },
        {},
        {},
        {},
        ["_id"],
        ["asc"],
        searchAfterIndex
      );
    case "STORE_ATTRIBUTES":
      return buildSearchPayload(
        {},
        {},
        {},
        {},
        ["_id"],
        ["asc"],
        searchAfterIndex
      );
    case "GEOLOCATION_WITH_FILTER":
      return buildSearchPayload(
        { isCompetitor: false, priceSource: "PriceGen" },
        {},
        {},
        {},
        ["_id"],
        ["asc"],
        searchAfterIndex
      );
  }
}

function fillDataIntoSourceArray(
  elasticHitsObject: ElasticSearchResponseObject,
  storeDetailList
) {
  elasticHitsObject.hits.hits
    .map((hit) => hit._source)
    .forEach((storeDetail) => {
      storeDetailList.push(storeDetail);
    });
  return storeDetailList;
}

function mergeGelocationAndStoreAttributes(
  geolocationResponse: ListViewApiResponseObject,
  storeattributesResponse: ListViewApiResponseObject
) {
  const geolocation = geolocationResponse.elasticSourceData
    .map(flattenPricePush)
    .map(prepareProposedPriceKeys);
  const storeattributes = storeattributesResponse.elasticSourceData;

  const geolocationImportCodeMap = new Map(
    geolocation.map((source) => [source.importcode, source])
  );
  const storeattributesStoreIdMap = new Map(
    storeattributes.map((source) => [source.storeId, source])
  );

  geolocationImportCodeMap.forEach((geolocation, importcode) => {
    geolocation.marketMoveTag =
      storeattributesStoreIdMap.get(importcode)?.marketMoveTag;
    geolocation.strategyTestTag =
      storeattributesStoreIdMap.get(importcode)?.strategyTestTag;
    geolocation.ownSiteTag =
      storeattributesStoreIdMap.get(importcode)?.ownSiteTag;
  });
  console.log(
    "visops-list-view: geolocation",
    geolocationImportCodeMap.size,
    "and storeattributes",
    storeattributesStoreIdMap.size,
    "data merged "
  );
  return geolocationImportCodeMap;
}
